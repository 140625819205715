import styles from './candidate.module.css';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/joy';
import { Candidate, CandidateItem } from 'api-types';
import { useState } from 'react';
import { CandidateProfile } from './profile/CandidateProfile';
import { Drawer } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { LinkediInLogo } from '../../components/Logo/LinkedinLogo';
import { humanizeDateRange } from './utils';

interface CandidateListItemProps {
  candidate: CandidateItem;
  renderActions?: (candidate: Candidate) => React.ReactNode;
}

export function CandidateListItem({ candidate, renderActions }: CandidateListItemProps) {
  const topTwoExperiences = candidate.experiences?.slice(0, 2);
  const highestEducation =
    candidate.education && candidate.education.length > 0 ? candidate.education[0] : undefined;
  const aiSummaryNotes = candidate.summaryNotes;

  const [profileOpen, setProfileOpen] = useState(false);
  return (
    <>
      <Card className={styles.card} key={candidate.id} sx={{ backgroundColor: '#fff' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems={'center'}>
            <div>
              <Typography
                level="title-md"
                fontWeight={'lg'}
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {`${candidate.firstName} ${candidate.lastName || ''}`}
                {candidate?.linkedinUrl && <LinkediInLogo url={candidate?.linkedinUrl} />}
              </Typography>
              <div className={styles.cardHeading}>
                <Typography level="body-sm" gutterBottom>
                  {candidate.experiences?.[0]?.position}
                </Typography>
                <Typography level="body-sm" gutterBottom>
                  {candidate.city}, {candidate.state}
                </Typography>
              </div>
            </div>
            {renderActions?.(candidate)}
          </Box>

          <Divider />
          <div className={styles.candidateRow}>
            <div>
              {aiSummaryNotes && (
                <div className={styles.section}>
                  <SectionHeader
                    title="AI Summary"
                    startDecorator={<AutoAwesomeOutlinedIcon fontSize="small" />}
                  />
                  <Typography level="body-sm">{aiSummaryNotes}</Typography>
                </div>
              )}
              <div className={styles.flexSection}>
                <SectionHeader title="Experience" />
                {topTwoExperiences && (
                  <SectionList
                    sectionItems={topTwoExperiences?.map(e => {
                      return {
                        contents: [
                          `${e.position} at ${e.company}`,
                          e.startDate ? humanizeDateRange(e.startDate, e.endDate) : '',
                        ],
                      };
                    })}
                  />
                )}
              </div>

              {/* {highestEducation && (
                <div className={styles.flexSection}>
                  <SectionHeader title="Education" />
                  <SectionList
                    sectionItems={[
                      {
                        contents: [
                          `${pascalCase(highestEducation.degree || '')} at ${highestEducation.institute}`,
                        ],
                      },
                    ]}
                  />
                </div>
              )} */}
            </div>
            <div className={styles.candidateItemfooter}>
              <Button
                variant="plain"
                color="neutral"
                size="sm"
                onClick={() => setProfileOpen(true)}
              >
                View full profile
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Drawer anchor={'right'} open={Boolean(profileOpen)} onClose={() => setProfileOpen(false)}>
        <div className={styles.drawerContainer}>
          <CandidateProfile candidate={candidate} renderReasoning />
        </div>
      </Drawer>
    </>
  );
}

export function SectionHeader({
  title,
  startDecorator,
}: {
  title: string;
  startDecorator?: React.ReactNode;
}) {
  return (
    <Typography
      level="body-sm"
      className={styles.sectionHeader}
      fontWeight={'lg'}
      gutterBottom
      startDecorator={startDecorator}
    >
      {title}
    </Typography>
  );
}

export function SectionList({ sectionItems }: { sectionItems: { contents: string[] }[] }) {
  return (
    <div>
      {sectionItems?.map(item => (
        <List marker="circle" size="sm" sx={{ paddingTop: 0 }}>
          <ListItem
            sx={{
              paddingTop: 0,
              lineHeight: 'initial',
              height: 'initial',
              minBlockSize: 'initial',
            }}
          >
            <div className={styles.experience}>
              {item.contents.map(content => (
                <Typography level="body-sm">{content}</Typography>
              ))}
            </div>
          </ListItem>
        </List>
      ))}
    </div>
  );
}
